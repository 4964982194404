import { useMemo } from "react";

export interface IUseTextSizeProps {
  content: string | undefined | null;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  transform?(str: string): string;
}

export function useTextSize({
  content = "",
  fontFamily = "Nunito Sans, sans-serif",
  fontSize = "16px",
  fontWeight = "800",
  transform = (a) => a,
}: IUseTextSizeProps) {
  const span = useMemo(() => {
    const element = document.body.appendChild(document.createElement("span"));
    element.style.position = "fixed";
    element.style.opacity = "0";
    element.style.pointerEvents = "none";
    return element;
  }, []);
  return useMemo(() => {
    span.style.fontSize = fontSize;
    span.style.fontWeight = fontWeight;
    span.style.fontFamily = fontFamily;
    span.textContent = transform(content ?? "");
    return span.getBoundingClientRect().width;
  }, [span, content, fontFamily, fontSize, fontWeight, transform]);
}
